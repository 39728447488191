import styles from './Formats.module.scss';
import Container from '../container/Container';
import { getNextPrice } from '../../utils/goods';
import { useState } from 'react';
import FormatBuyPopup from '../popups/formatBuy/FormatBuyPopup';

const soldOut = [
  'gonka2024_524901_2004',
  'gonka2024_524901_2206',
  'gonka2024_524901_2306',
  'gonka2024_498817_0607',
  'gonka2024_498817_0707',
  'gonka2024_551487_2707',
  'gonka2024_520555_0806',
];

function Formats({formats, city}) {
  const [format, setFormat] = useState(null);
  const [isOpenPopup, setOpenPopup] = useState(false);

  const handleFormatClick = (format) => {
    setFormat(format);
    setOpenPopup(true);
  };

  return (
    <Container>
      <section className={styles.formats} id="formats">
        <ul className={styles.list}>
          { formats.map((format, index) => (
            <li className={styles.item} key={format.public_id}>
              <h3 className={styles.title}>{format.title}</h3>
              <p className={styles.desc}>
                {format.desc}
              </p>
              <p className={styles.place}>
                <span className={styles.label}>Место: </span>
                <span>{format.address}</span>
              </p>
              <p className={styles.priceWrap}>
                <span className={styles.label}>Стоимость:</span>
                <span className={styles.price}>{format.team_all ? format.price * format.max_count : format.price} ₽</span>
              </p>
              <p className={styles.quantityTicketsWrap}>
                Осталось билетов:
                <span className={styles.quantityTickets}> {getNextPrice(format).tickets_left.athlete}</span>
              </p>
              { getNextPrice(format).nextPrice != null &&
                <p className={styles.nextPriceWrap}>
                  Следующая цена:
                  <span  className={styles.nextPrice}>{format.team_all ? getNextPrice(format).nextPrice * format.max_count : getNextPrice(format).nextPrice} ₽</span>
                </p>
              }
              <button className={styles.btn} type="button" onClick={() => handleFormatClick(format)} disabled={format.tickets_left.athlete < 1}>{format.tickets_left.athlete < 1 ? `Распродано` : `Купить`}</button>
            </li>
          )) }

          <li className={`${styles.item} ${styles.itemCorp}`}>
            <h3 className={styles.title}>Корпоративное участие</h3>
            <p className={styles.desc}>
              Чтобы ваша компания стала частью Гонки Героев, необходимо заполнить простую форму и оставить заявку.
            </p>
              {
                soldOut.includes(city?.public_id) ?
                <button className={styles.btn} disabled>Распродано</button> :
                <a className={styles.btn} href="/corp">Участвовать</a>
              }
          </li>
        </ul>
      </section>

      { isOpenPopup && <FormatBuyPopup handleStatePopup={setOpenPopup} format={format} /> }
    </Container>
  );
}

export default Formats;
