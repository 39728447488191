import { ReactComponent as IconBeginner } from '../../images/svg/icon-beginner.svg';
import { ReactComponent as IconFun } from '../../images/svg/icon-fun.svg';
import { ReactComponent as IconPro } from '../../images/svg/icon-pro.svg';
import { ReactComponent as IconStrength } from '../../images/svg/icon-strength.svg';
import { ReactComponent as IconStamina } from '../../images/svg/icon-stamina.svg';
import { ReactComponent as IconSpeed } from '../../images/svg/icon-speed.svg';

const formats = [
  {
    id: 0,
    icon: IconBeginner,
    name: `Новичок`,
    title: `Хочешь попробовать, но боишься, что не в форме?`,
    desc: `Препятствия - это развлечения, а наши инструкторы - твои верные соратники. Ты сильнее, чем думаешь.`,
    recFormat: `Приключение, командный`,
    params: [
      {
        id: 0,
        icon: IconStrength,
        label: `Сила`,
        value: `12`,
      },
      {
        id: 1,
        icon: IconStamina,
        label: `Выносливость`,
        value: `23`,
      },
      {
        id: 2,
        icon: IconSpeed,
        label: `Скорость`,
        value: `34`,
      },
    ]
  },
  {
    id: 1,
    icon: IconFun,
    name: `Любитель`,
    title: `Ты долго потел в зале и отважился проверить свои навыки на трассе?`,
    desc: `Ни один тренажер не сравнится с драйвом Гонки Героев. Перейди на новый уровень.`,
    recFormat: `Масс-старт`,
    params: [
      {
        id: 0,
        icon: IconStrength,
        label: `Сила`,
        value: `39`,
      },
      {
        id: 1,
        icon: IconStamina,
        label: `Выносливость`,
        value: `49`,
      },
      {
        id: 2,
        icon: IconSpeed,
        label: `Скорость`,
        value: `59`,
      },
    ]
  },
  {
    id: 2,
    icon: IconPro,
    name: `Профессионал`,
    title: `Приди первым и покажи пример`,
    desc: `Ты можешь оставить позади многих, но сможешь ли прийти на финиш самым первым? Прими вызов и прояви все свое мастерство. Стань примером для участников и героем для зрителей.`,
    recFormat: `Чемпионат`,
    params: [
      {
        id: 0,
        icon: IconStrength,
        label: `Сила`,
        value: `69`,
      },
      {
        id: 1,
        icon: IconStamina,
        label: `Выносливость`,
        value: `49`,
      },
      {
        id: 2,
        icon: IconSpeed,
        label: `Скорость`,
        value: `87`,
      },
    ]
  },
];

export { formats };
