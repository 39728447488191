import styles from './Descriptions.module.scss';
import descImg11x from '../../images/desc1@1x.png';
import descImg21x from '../../images/desc2@1x.png';
import descImg31x from '../../images/desc3@1x.png';
import { useEffect, useState } from 'react';
import { useRect } from '../../hooks/useRect';

function Descriptions() {
  const [isActiveBlockCont1Y, setActiveBlockCont1Y] = useState(false);
  const [isActiveBlockCont2Y, setActiveBlockCont2Y] = useState(false);
  const [isActiveBlockCont3Y, setActiveBlockCont3Y] = useState(false);

  const [content1Rect, content1Ref] = useRect();
  const [content2Rect, content2Ref] = useRect();
  const [content3Rect, content3Ref] = useRect();

  useEffect(() => {
    const windowY = Math.floor(window.scrollY);

    if (windowY > (content1Rect.top + windowY - 600)) setActiveBlockCont1Y(true);

    if (windowY > (content2Rect.top + windowY - 600)) setActiveBlockCont2Y(true);

    if (windowY > (content3Rect.top + windowY - 600)) setActiveBlockCont3Y(true);
  });

  return (
    <>
      <section className={styles.descriptions}>
        <div className={styles.descriptionsTop}>
          <b className={styles.slogan}><span className={styles.sloganHero}>Героями</span> не рождаются</b>
          <p className={styles.topText}>Спортивно-развлекательный экшен, который изменит тебя к лучшему</p>
        </div>

        <div className={`${styles.blockContainer} ${isActiveBlockCont1Y ? styles.blockCont1Animate : ''}`} ref={content1Ref}>
          <img className={`${styles.blockImg} ${styles.blockImg1}`} src={descImg11x} alt="Участники"/>
          <div className={`${styles.content} ${styles.content1}`}>
            <div className={`${styles.contentWrapper} ${styles.contentWrapper1}`}>
              <h2 className={styles.title}>Приключение доступное каждому</h2>
              <p className={styles.text}>
                Думаешь, что до спортивных состязаний, тебе как до Китая? Не пасуй. Мы продумали гонку так, чтобы каждому
                было интересно: от офисных работников до профессиональных спортсменов. Даже неподготовленные участники
                могут пройти трассу достойно. В нашей гонке нет проигравших. Участие - уже победа.
              </p>
              <h2 className={styles.title}>Почувствуй драйв</h2>
              <p className={styles.text}>
                Давно ли ты испытывал чувство беззаботной радости от происходящего? Помнишь, как в детстве с друзьями
                вы катались на тарзанке и забирались на деревья? Гонка Героев предложит тебе еще больше приключений.
                Ежедневная рутина никогда не даст эмоций от которых захватывает дух — их подарит Гонка.
              </p>
            </div>
          </div>
        </div>

        <div className={`${styles.blockContainer} ${styles.blockContainer2} ${isActiveBlockCont2Y ? styles.blockCont2Animate : ''}`} ref={content2Ref}>
          <div className={styles.img2Wrap}>
            <img className={`${styles.blockImg} ${styles.blockImg2}`} src={descImg21x} alt="Участники"/>
          </div>
          <div className={`${styles.content} ${styles.content2}`}>
            <div className={`${styles.contentWrapper} ${styles.contentWrapper2}`}>
              <h2 className={styles.title}>Приглашай друзей</h2>
              <p className={styles.text}>
                Кто всегда придет на помощь в сложной ситуации? С кем приятно разделить радость победы? Идеальная команда
                ближе, чем ты думаешь: это твои друзья. Бери всю компанию на Гонку - эмоций хватит на всех! Герой не тот,
                кто может все, а тот, кто приводит команду к финишу. Покажите, что такое настоящая дружба на деле.
              </p>
              <h2 className={styles.title}>Найди единомышленников</h2>
              <p className={styles.text}>
                Хочешь пойти на гонку один? Уважаем и поддерживаем твою решимость. Ты встретишь множество близких по духу
                людей, многие из которых станут твоими верными друзьями на трассе и после финиша.
              </p>
            </div>
          </div>
        </div>

        <div className={`${styles.blockContainer} ${styles.blockContainer3} ${isActiveBlockCont3Y ? styles.blockCont3Animate : ''}`} ref={content3Ref}>
          <img className={`${styles.blockImg} ${styles.blockImg3}`} src={descImg31x} alt="Участники"/>
          <div className={`${styles.content} ${styles.content3}`}>
            <div className={`${styles.contentWrapper} ${styles.contentWrapper3}`}>
              <h2 className={styles.title}>Стань лучше</h2>
              <p className={styles.text}>
                Преодолевая препятствия, ты побеждаешь свои страхи. Ты открываешь себя с новой стороны, и этот опыт
                остается с тобой навсегда. Гонка изменит твою жизнь: проверено тысячами участников.
              </p>
            </div>
          </div>

          <a className={styles.anchor} href="#citychoice">Принять участие</a>
        </div>

      </section>
    </>
  );
}

export default Descriptions;
