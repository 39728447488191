import img1 from '../../images/whoFor1@1x.jpg';
import img2 from '../../images/whoFor2@1x.png';
import img3 from '../../images/whoFor3@1x.png';
import img4 from '../../images/whoFor4@1x.png';

const whoForList = [
  {
    id: 0,
    title: `Для болельщиков`,
    desc: `Пока вы будете потеть на трассе, ваших болельщиков ждет отличный выходной на природе: комфортная фан-зона с развлечениями и шоу-программа с выступлениями артистов.`,
    background: img1,
  },
  {
    id: 1,
    title: `Для участников`,
    desc: `Настоящее приключение, доступное каждому, независимо от уровня подготовки. Открой себя с новой стороны!`,
    background: img2,
  },
  {
    id: 2,
    title: `Для детей`,
    desc: `Героем можно стать в любом возрасте. Поэтому мы проводим не только взрослые соревнования, но и специальные детские забеги. Кто готов - на старт!`,
    background: img3,
  },
  {
    id: 3,
    title: `Для семьи`,
    desc: `Приходи на Гонку героев всей семьей. Даже если ваши близкие не готовы присоединиться к вам на трассе, они станут свидетелями вашего триумфа на финише. Ярких эмоций хватит на всех!`,
    background: img4,
  },
];

export { whoForList };
