import styles from './Header.module.scss';
import { ReactComponent as Logo } from '../../images/logo-liga.svg';
import { ReactComponent as IconCart } from '../../images/svg/icon-cart.svg';
import { ReactComponent as IconAuth } from '../../images/svg/icon-auth.svg';
import React, { useEffect, useState } from 'react';
// import HideScroll from '../common/HideScroll';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSelectedCity } from '../../store/app/selectors';

const Links = [
  { id: 0, name: `Мероприятия`, link: `https://heroleague.ru/calendar`, },
  { id: 1, name: `Магазин`, link: `https://heroleague.ru/shop/`, },
  { id: 2, name: `Партнёрство`, link: `https://heroleague.ru/partners#application`, },
  { id: 3, name: `Корпоративным клиентам`, link: `https://heroleague.ru/corp`, },
  { id: 4, name: `О нас`, link: `https://heroleague.ru/about`, },
];

const Header = () => {
  const [stateMenu, setStateMenu] = useState(false);
  const [links, setLinks] = useState(Links);

  const handleMenuState = (evt) => {
    evt.preventDefault();
    setStateMenu(prev => !prev);
  };

  const city = useSelector(getSelectedCity);

  useEffect(() => {
    if (city?.geo?.lat) {
      setLinks([...Links, { id: 5, name: `Как добраться`, link: `#whereis`, }]);
    }
  }, [city]);

  return (
    <header className={styles.header} data-state={stateMenu}>
      {/*<h1 className="visually-hidden">Забег по пересеченной местности Sparrow Hills Trail</h1>*/}
      {/*{stateMenu && <HideScroll />}*/}
      <Link to="/race">
        <Logo className={styles.logo} width="72" height="48" />
      </Link>
      <div className={styles.navMobile}>
        <ul className={styles.list}>
          {links.map(link => (
            <li key={link.id} className={styles.item}><a className={styles.link} href={link.link} onClick={() => setStateMenu(false)}>{link.name}</a></li>
          ))}
        </ul>
        <a className={styles.btn} href="#citychoice">Принять участие</a>
        <div className={styles.btnWrap}>
          <a className={`${styles.headerLink} ${styles.link}`} href="https://heroleague.ru/basket" target="_blank" rel="noreferrer">
            <IconCart />
            <span className="visually-hidden">В корзину</span>
          </a>
          <a className={`${styles.headerLink} ${styles.link}`} href="https://heroleague.ru/login" target="_blank" rel="noreferrer">
            <IconAuth />
            <span className="visually-hidden">Авторизация</span>
          </a>
        </div>
      </div>
      <button className={styles.btnMenuMobile} aria-label="Меню" onClick={handleMenuState}>
        <div className={styles.gam} />
      </button>
    </header>
  );
};

export default Header;
