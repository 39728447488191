const cityRoutes = [
  {
    id: 0,
    path: `/:customPath`,
  },
  {
    id: 1,
    path: `/:customPath/sheregesh`,
    cityName: `sheregesh`,
    cityId: 1492252,
  },
  {
    id: 2,
    path: `/:customPath/spb`,
    cityName: `spb`,
    cityId: 498817,
  },
  {
    id: 3,
    path: `/:customPath/ekb`,
    cityName: `ekb`,
    cityId: 1486209,
  },
  {
    id: 4,
    path: `/:customPath/samara`,
    cityName: `samara`,
    cityId: 499099,
  },
  {
    id: 5,
    path: `/:customPath/vladivostok`,
    cityName: `vladivostok`,
    cityId: 2013348,
  },
  {
    id: 6,
    path: `/:customPath/novosibirsk`,
    cityName: `novosibirsk`,
    cityId: 1496747,
  },
  {
    id: 7,
    path: `/:customPath/kazan`,
    cityName: `kazan`,
    cityId: 551487,
  },
  {
    id: 8,
    path: `/:customPath/chelyabinsk`,
    cityName: `chelyabinsk`,
    cityId: 1508291,
  },
  {
    id: 9,
    path: `/:customPath/tula`,
    cityName: `tula`,
    cityId: 480562,
  },
  {
    id: 10,
    path: `/:customPath/ufa`,
    cityName: `ufa`,
    cityId: 479561,
  },
  {
    id: 11,
    path: `/:customPath/moscow`,
    cityName: `moscow`,
    cityId: 524901,
  },
  {
    id: 12,
    path: `/:customPath/mend`,
    cityName: `mend`,
    cityId: 527579,
  },
  {
    id: 13,
    path: `/:customPath/murmansk`,
    cityName: `murmansk`,
    cityId: 524305,
  },
  {
    id: 14,
    path: `/:customPath/nn`,
    cityName: `nn`,
    cityId: 520555,
  },
  {
    id: 15,
    path: `/:customPath/omsk`,
    cityName: `omsk`,
    cityId: 1496153,
  },
  {
    id: 16,
    path: `/:customPath/voronezh`,
    cityName: `voronezh`,
    cityId: 472045,
  },
  {
    id: 17,
    path: `/:customPath/volgograd`,
    cityName: `volgograd`,
    cityId: 472757,
  },
];

export { cityRoutes };
