import { times } from './times';

export const setCitiesOptions = (arr, value = `id`, label = `address`) => {
  if (arr.length > 0) {
    const filteredCities = [];

    arr.map(el => {
      filteredCities.push(
        {
          value: el.public_id,
          label: el[label] + ' – ' + times.getNumberDate(el.start_time),
          cityId: el.city[value],
          registrationClose: el.registration_close,
          cityPublicId: el.public_id,
        }
      );
    });

    return filteredCities;
  }
  return [];
};
