import styles from './TableFormats.module.scss';
import Container from '../container/Container';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';


const formatsTable = {
  headers: ['', 'Массовый старт', 'Команда', 'Место в команде', 'Чемпионат'],
  tableRows: [
    ['Старт', 'Волнами', 'Командой', 'Командой', 'Волнами'],
    ['Хронометраж', 'Индивидуальный', 'Командный', 'Командный', 'Индивидуальный'],
    ['Судейство на препятствиях', 'Нет', 'Нет', 'Нет', 'Да'],
    ['Сопровождение инструктора', 'Нет', 'Да', 'Да', 'Нет'],
    ['Помощь на препятствиях', 'Разрешена', 'Разрешена', 'Разрешена', 'Запрещена'],
    ['Обязательное прохождение препятствий', 'Нет', 'Нет', 'Нет', 'Да'],
    ['Награждение победителей', 'Нет', 'Нет', 'Нет', 'Да'],
    ['Жетон на финише', 'Да', 'Да', 'Да', 'Да'],
    ['Питание на финише', 'Да', 'Да', 'Да', 'Да'],
    ['Вода на трассе', 'Да', 'Да', 'Да', 'Да'],
    ['Медицинское сопровождение', 'Да', 'Да', 'Да', 'Да'],
    ['Душ', 'Да', 'Да', 'Да', 'Да'],
    ['Камера хранения', 'Да', 'Да', 'Да', 'Да'],
    ['Фотографии', 'Платно', 'Платно', 'Платно', 'Платно'],
  ],
};

function TableFormats() {
  const [activeColumn, setActiveColumn] = useState(-1);
  const [activeAccordion, setActiveAccordion] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const toggleAccordion = (index) => {
    if (index === activeAccordion) {
      setActiveAccordion(-1);
    } else {
      setActiveAccordion(index);
    }
  };

  return (
    <section className={styles.tableFormats}>
      <Container>
        <div className={styles.wrapper}>
          <h2 className={styles.title}><span className={styles.titleWrap}>Для каждого своя</span></h2>

          {!isMobile && (
            <div className={styles.tableWrap}>
              <table className={styles.table}>
                <thead>
                <tr>
                  {formatsTable.headers.map((th, index) => (
                    <th
                      {...(th.length > 0 && {className: `${styles.th} ${activeColumn > 0 && (activeColumn !== index && index > 0) ? styles.tdOpacity : ''}`})}
                      onMouseEnter={() => setActiveColumn(index)}
                      onMouseLeave={() => setActiveColumn(-1)}
                    >
                      {index > 0 && <span className={styles.thWrap}>{th}</span>}
                    </th>
                  ))}
                </tr>
                </thead>
                <tbody>
                {formatsTable.tableRows.map((tr) => (
                  <tr>
                    {tr.map((td, index) => (
                      <td
                        className={`${styles.td} ${index === 0 ? styles.tdLabel : ''} ${activeColumn > 0 && (activeColumn !== index && index > 0) ? styles.tdOpacity : ''}`}
                        onMouseEnter={() => setActiveColumn(index)}
                        onMouseLeave={() => setActiveColumn(-1)}
                      >
                        <span className={styles.tdWrap}>{td}</span>
                      </td>
                    ))}
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          )}

          {isMobile && (
            <div>
              {formatsTable.headers.slice(1).map((head, headIndex) => (
                <div>
                  <p className={`${styles.accordionHead} ${activeAccordion === headIndex ? styles.accordionHeadOpen : ''}`} onClick={() => toggleAccordion(headIndex)}>{head}</p>

                  {activeAccordion === headIndex && (
                    <table className={styles.table}>
                      <tbody>
                      {formatsTable.tableRows.map((tr) => (
                        <tr>
                          <td className={`${styles.td} ${styles.tdLabel}`}><span className={styles.tdWrap}>{tr[0]}</span></td>
                          <td className={styles.td}><span className={styles.tdWrap}>{tr[headIndex+1]}</span></td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
}

export default TableFormats;
