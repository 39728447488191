const faqList = [
  {
    id: 0,
    tabName: `Что такое гонка героев`,
    questions: [
      {
        id: 0,
        question: `«Гонка героев» — это:`,
        answer: <>
          <p>"Гонка Героев” - это серия драйвовых забегов с препятствиями. Рукоходы и переправы, рвы и поля с колючей проволокой, достойная награда и чувство гордости на финише - и это только часть твоего незабываемого приключения.</p>
          <p>Проект объединил сотни тысяч участников по всей стране, став для них неотъемлемой частью спортивного досуга. И все это профессиональные спортсмены? Нет. Участие доступно для желающих любого уровня подготовки. В любой момент тебе на помощь придут опытные инструкторы, которые ПОДДЕРЖАТ в прохождении сложных участков. Но не пытайся выйти сухим из ВОДЫ: ты будешь по колено в грязи и по уши в восторге.</p>
        </>,
      },
      {
        id: 1,
        question: `Какие виды препятствий меня ожидают?`,
        answer: <>
          <p>В каждом городе своя особенная трасса. Это и водные преграды, и высокие стены, наклонные заборы, колючая проволока, подземные тоннели, канаты, траншеи, рвы с водой и многое другое.</p>
        </>,
      },
      {
        id: 3,
        question: `Каким уровнем физической подготовки необходимо обладать для участия?`,
        answer: <>
          <p>К гонке допускаются участники с любым уровнем подготовки. Преодолеть дистанцию под силу каждому. Однако мы настоятельно не рекомендуем участвовать людям с хроническими заболеваниями, сердечно-сосудистой, дыхательной системы, травмами позвоночника и суставов, а также прочими травмами и заболеваниями, несовместимыми с занятиями спортом.</p>
        </>,
      },
    ],
  },
  {
    id: 1,
    tabName: `Регистрация`,
    questions: [
      {
        id: 0,
        question: `Как зарегистрироваться на гонку?`,
        answer: <p>Выбери вариант участия: ты побежишь один или со своей командой? Ознакомься с условиями участия и купи билет на гонку.</p>,
      },
      {
        id: 1,
        question: `Какие есть варианты участия в гонке?`,
        answer: <>
          <strong>Командный забег</strong>
          <p>Участвуй своим составом или найди новых друзей, став частью команды единомышленников</p>
          <ul>
            <li>От 18 лет</li>
            <li>Соревнуются команды из 10 человек</li>
            <li>Каждую команду сопровождает инструктор</li>
            <li>Время измеряется по последнему участнику команды, пришедшему на финиш</li>
          </ul>

          <strong>Массовый старт</strong>
          <ul>
            <li>От 18 лет</li>
            <li>Участники стартуют волнами по 10-20 человек каждую минуту</li>
            <li>Индивидуальный хронометраж</li>
          </ul>

          <strong>Чемпионат</strong>
          <p>Соревнование с профессиональным судейством и индивидуальным хронометражем. Участвуй в чемпионате, соревнуйся за призовые места!</p>
          <ul>
            <li>От 18 лет</li>
            <li>
              Категории участия:
              <ul>
                <li>Pro – мужчины и женщины. Абсолютный зачет.</li>
                <li>Age – мужчины и женщины. Награждение в возрастных категориях – 18-29, 30-34, 35-39, 40+. Возраст определяется по году рождения.</li>
              </ul>
            </li>
            <li>Призы и подарки от партнеров и спонсоров</li>
          </ul>
        </>,
      },
      {
        id: 2,
        question: `Как зарегистрировать корпоративную команду?`,
        answer: <>
          <p>Узнайте подробнее о вариантах корпоративного участия в разделе «Корпоративное участие <a href="https://heroleague.ru/corp#corpForm/" target="_blank" rel="noreferrer">https://heroleague.ru/corp</a> ». Или по адресу: <a href="mailto:corp@heroleague.ru">corp@heroleague.ru</a></p>
        </>,
      },
      {
        id: 3,
        question: `У меня нет своей команды — что делать?`,
        answer: <>
          <p>Если у тебя нет команды, ты можешь присоединиться к любой команде. Выбери подходящий вариант участия, купи билет на гонку, приезжай на полигон в день забега и познакомься с остальными членами твоей команды. В этом тебе помогут наши профессиональные инструкторы.</p>
        </>,
      },
      {
        id: 5,
        question: `Есть ли возрастные ограничения для участников?`,
        answer: <>
          <p>Да, к участию в «Гонке Героев» допускаются участники строго с 18 лет.</p>
        </>,
      },
      {
        id: 6,
        question: `Могу ли я вернуть или обменять билет на гонку?`,
        answer: <>
          <p>Да, можно вернуть денежные средства за билет, подав заявку не позднее чем за 5 дней до предстоящей гонки. Подробнее с условиями возврата можно ознакомиться ниже в разделе "Возврат билетов".</p>
        </>,
      },
    ],
  },
  {
    id: 2,
    tabName: `Покупка билета`,
    questions: [
      {
        id: 0,
        question: `Что входит в стоимость билета?`,
        answer: <>
          <ul>
            <li>трансфер из города либо от ближайшего транспортного узла (в зависимости от региона);</li>
            <li>раздевалка, гардероб, душевые**;</li>
            <li>манишка с индивидуальным номером;</li>
            <li>развлекательная программа;</li>
            <li>медицинское сопровождение;</li>
            <li>сопровождение инструктора*;</li>
            <li>награждение памятным жетоном;</li>
          </ul>
          <p>* зависит от формата участия.</p>
          <p>** в летний период</p>
        </>,
      },
      {
        id: 1,
        question: `Как мне купить билет себе и друзьям?`,
        answer: <>
          <p>Если ты готов сам оплатить всех участников, выбери нужное количество билетов, оплати, а далее зарегистрируй друзей.</p>
        </>,
      },
    ],
  },
  {
    id: 3,
    tabName: `День до гонки`,
    questions: [
      {
        id: 0,
        question: `Какие документы необходимо взять с собой?`,
        answer: <>
          <ul>
            <li>Копию паспорта или иной документ, удостоверяющий личность;</li>
            <li>Медицинская справка <a href="https://heroleague.ru/about/med-spravka/" target="_blank" rel="noreferrer">https://heroleague.ru/about/med-spravka/</a> (обязательно для участия);</li>
            <li>Электронный билет.</li>
          </ul>
        </>,
      },
      {
        id: 1,
        question: `Как узнать своё время старта?`,
        answer: <>
          <p>Примерное время твоего старта и стартовый номер будут высланы тебе на электронную почту, указанную при покупке билета, не позднее чем за 24 часа до <b>забега</b>.</p>
        </>,
      },
      {
        id: 3,
        question: `Как одеться на гонку?`,
        answer: <>
          <p>На трассу допускаются участники, одетые в удобную спортивную форму, которая не стесняет движений и не причиняет вреда другим участникам забега. Имей в виду, что одежда значительно испачкается и промокнет, поэтому захвати с собой сменную одежду.</p>
        </>,
      },
      {
        id: 4,
        question: `Как добраться на гонку?`,
        answer: <>
          <p>Смотри схему проезда на полигон на машине или общественном транспорте, размещенную на карте конкретного мероприятия.</p>
        </>,
      },
    ],
  },
  {
    id: 4,
    tabName: `День гонки`,
    questions: [
      {
        id: 0,
        question: `Во сколько мне необходимо прибыть на полигон?`,
        answer: <>
          <p>Рекомендуем приехать за 1,5 часа до времени старта твоей команды.</p>
        </>,
      },
      {
        id: 1,
        question: `Как проходит регистрация участников на месте?`,
        answer: <>
          <p>Когда приедешь на полигон, следуй по навигационным баннерам к зоне регистрации. Там после обработки документов волонтёр выдаст тебе стартовый пакет для участия гонке.</p>
        </>,
      },
      {
        id: 2,
        question: `Могу ли я переодеться и сдать на хранение вещи?`,
        answer: <>
          <p>Да, после регистрации ты можешь пройти в раздевалку и переодеться. Вещи можно оставить в камере хранения по номеру участника на манишке. Тем не менее мы не рекомендуем брать с собой на полигон ценные вещи.</p>
        </>,
      },
      {
        id: 3,
        question: `Проводит ли кто-то разминку и инструктаж по технике безопасности?`,
        answer: <>
          <p>Перед стартом инструктор команды проводит разминку и инструктаж с участниками команды. В дополнение общая энергичная разминка проводится профессиональным инструктором со сцены.</p>
        </>,
      },
      {
        id: 4,
        question: `С каким промежутком стартуют команды?`,
        answer: <>
          <p>Команды стартуют с интервалом в 1 минуту.</p>
        </>,
      },
      {
        id: 5,
        question: `Есть ли точки с питьевой водой на гонке?`,
        answer: <>
          <p>Да, мы обеспечиваем водой всех участников во время гонки.</p>
        </>,
      },
      {
        id: 6,
        question: `Есть ли медицинское обслуживание на гонке?`,
        answer: <>
          <p>Да, на каждом мероприятие организовано дежурство скорой медицинской помощи.</p>
        </>,
      },
      {
        id: 7,
        question: `Могу ли я принять душ после гонки?`,
        answer: <>
          <p>Да, в летний период на мероприятии будет организован теплый душ. Не забудь полотенце.</p>
        </>,
      },
      {
        id: 8,
        question: `Где и когда я могу узнать результаты моей команды?`,
        answer: <>
          <p>Результаты будут опубликованы на сайте в течение 3-5 дней.</p>
        </>,
      },
      {
        id: 9,
        question: `А что, если я зритель?`,
        answer: <>
          <p>У нас на мероприятии есть развлекательная программа для взрослых и отдельная игровая зона для детей. Вход на мероприятие для зрителей — бесплатный.</p>
        </>,
      },
      {
        id: 10,
        question: `Есть ли парковка для автомобилей?`,
        answer: <>
          <p>Для участников и зрителей предусмотрена бесплатная парковка недалеко от входа на территорию мероприятия.</p>
        </>,
      },
    ],
  },
  {
    id: 5,
    tabName: `Фото и видео`,
    questions: [
      {
        id: 0,
        question: `Где я могу найти свои фото?`,
        answer: <>
          <p>Фотографии будут доступны для покупки на сайте <a href="https://photo.heroleague.ru/" target="_blank" rel="noreferrer">https://photo.heroleague.ru/</a> в течение 5 дней после гонки.</p>
        </>,
      },
    ],
  },
  {
    id: 6,
    tabName: `Возврат билетов`,
    questions: [
      {
        id: 0,
        question: `Как я могу вернуть билет?`,
        answer: <>
          <ol>
            <li>Войди в личный кабинет.</li>
            <li>Найди заказ, который желаешь вернуть в разделе "Последние заказы".</li>
            <li>Нажми "Оформить возврат" напротив возвращаемого заказа.</li>
            <li>Проставь галочки напротив возвращаемых билетов.</li>
            <li>Согласись с условиями возврата, также проставив галочки.</li>
            <li>Нажми кнопку "Вернуть".</li>
            <li>Ожидай возврата денежных средств. Сроки поступления зависят от Банка и, как правило, не составляют более 2-х недель. Если денежные средства не поступили в указанные сроки - обращайся в свой банк.</li>
            <li>Обрати внимание, что оформить возврат можно не позднее, чем за 5 дней до начала мероприятия. За 5 дней до мероприятия и после него денежные средства блокируются и возврат осуществить нельзя.</li>
          </ol>
        </>,
      },
    ],
  },
  {
    id: 7,
    tabName: `Работать с нами`,
    questions: [
      {
        id: 0,
        question: `Я спортсмен, хочу быть инструктором`,
        answer: <>
          <p>Отправь заявку на электронный адрес <a href="mailto:sport@heroleague.ru">sport@heroleague.ru</a>.</p>
        </>,
      },
      {
        id: 1,
        question: `Как стать волонтёром?`,
        answer: <>
          <p>Отправь электронное письмо на адрес <a href="mailto:volunteer@heroleague.ru">volunteer@heroleague.ru</a>, указав в теме письма "волонтер", а в содержании всю информацию о себе и город участия.</p>
        </>,
      },
    ],
  },
];

export { faqList };
