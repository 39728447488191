import styles from './CityChoice.module.scss';
import Select from 'react-select';
import './CityChoice.scss';
import Formats from '../formats/Formats';
import { useEffect, useState } from 'react';
import api from '../../api';
import { setCitiesOptions } from '../../utils/common';
import { cityRoutes } from '../../const/routes';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsList, getOpenPageStatus, getSelectedCity } from '../../store/app/selectors';
import { setOpenPageStatus, setSelectedCity } from '../../store/action';
import { fetchEventsList } from '../../store/api-actions';


function CityChoice({cityIdScreen}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formats, setFormats] = useState([]);
  const [registrationClosed, setRegistrationClosed] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [defaultCityOption, setDefaultCityOption] = useState({});

  const events = useSelector(getEventsList);
  const selectedCity = useSelector(getSelectedCity);
  const isFirstOpenPage = useSelector(getOpenPageStatus);

  const registrationCloseFilter = (event_city) => {
    const registrationCloseTime = new Date(event_city.registration_close * 1000);
    const currentTime = new Date();
    if (registrationCloseTime < currentTime) {
      return true
    } else {
      return false
    }
  };

  useEffect(() => {
    !events && dispatch(fetchEventsList());

    if (!selectedCity && events) {
      const dateNow = Math.round(Date.now() / 1000);

      if (cityIdScreen) {
        const filterCity = events.filter((el) => el.city.id === cityIdScreen);
        const foundCity = filterCity.find((el) => el.city.id === cityIdScreen && el.registration_close > dateNow) ?? filterCity[filterCity?.length - 1];
        const cityOption = setCitiesOptions(events).find((el) => el.cityPublicId === foundCity.public_id);

        setDefaultCityOption(cityOption);
        dispatch(setSelectedCity(foundCity));
      } else {

        setDefaultCityOption(setCitiesOptions(events)[0]);
        dispatch(setSelectedCity(events[0]));
      }

      setCityOptions(setCitiesOptions(events));
    }
  }, [events]);

  useEffect(() => {
    if (events && selectedCity) {
      const cityOption = setCitiesOptions(events).find((el) => el.value === selectedCity.public_id);

      setCityOptions(setCitiesOptions(events));
      setDefaultCityOption(cityOption);
      setRegistrationClosed(registrationCloseFilter(selectedCity))
      fetchFormats(selectedCity.public_id);
    }
  }, [selectedCity]);

  const fetchFormats = async (publicId) => {
    const eventCityPublicId = publicId || selectedCity.public_id;
    const filterCity = events.filter((el) => el.city.id === cityIdScreen); // поиск всех мероприятий в выбранном городе
    const currentCityIndex = filterCity.findIndex((el) => el.public_id === publicId);

    try {
      const response = await api.get(`/api/event_format/event/${eventCityPublicId}`);

      if (response.data) {
        if (response?.data?.values?.length > 0) {
          const formatTicketsLeft = response.data.values.find((el) => el?.tickets_left?.athlete > 0);

          if (isFirstOpenPage && !formatTicketsLeft && currentCityIndex < (filterCity.length - 1)) {
            const nextCity = filterCity[currentCityIndex + 1];

            dispatch(setSelectedCity(nextCity));
            setDefaultCityOption(setCitiesOptions([nextCity])[0]);
          } else {
            setFormats(response.data.values)
          }

        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleChangeCity = (evt) => {
    const city = cityRoutes.find((el) => el.cityId === evt.cityId);

    dispatch(setOpenPageStatus(false));

    if (city) {
      const foundCity = events.find((el) => el.public_id === evt.value);

      history.push(`/race/${city.cityName}`);
      dispatch(setSelectedCity(foundCity));
    }
  };

  return (
    <section className={styles.cityChoice}>
      <div className={styles.cityChoiceBack}>
        <div className={styles.wrapper} id="citychoice">
          <h2 className={styles.title}><span className={styles.titleWrap}>В твоем городе</span></h2>
          <p className={styles.desc}>Выбери свой город</p>
          <div className={styles.selectWrap}>
            { cityOptions.length > 0 &&
              <Select
                className="citySelect"
                classNamePrefix="citySelect"
                options={cityOptions}
                value={defaultCityOption}
                // defaultValue={defaultCityOption}
                placeholder="Выберите город"
                onChange={handleChangeCity}
                isSearchable={false}
              />
            }
          </div>
          { registrationClosed && <section id="formats"><h2 className={styles.title2}>Продажа завершена</h2></section> }
        </div>
      </div>

      { (formats.length > 0 && !registrationClosed ) && <Formats formats={formats} city={selectedCity}/> }
    </section>
  );
}

export default CityChoice;
