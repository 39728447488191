import styles from './Gallery.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import { galleryPhotos } from './gallery-data';


// Установка модулей Swiper
SwiperCore.use([Navigation]);

function Gallery() {
  const sliderParams = {
    slidesPerView: `auto`,
    loop: true,
    grabCursor: true,
    // navigation: {
    //   prevEl: `.${styles.sliderNavPrev}`,
    //   nextEl: `.${styles.sliderNavNext}`,
    // },
  }

  return (
    <section className={styles.gallery}>

      <div className={styles.sliderWrap}>
        <Swiper
          {...sliderParams}
          className={styles.slider}
        >

          { galleryPhotos.map((photo) => (
            <SwiperSlide className={styles.slide} key={photo.id}>
              <li className={styles.item} >
                <img
                  width="300"
                  height="210"
                  src={photo.img}
                  alt={`Фото ${photo.id}`}/>
              </li>
            </SwiperSlide>
          )) }

        </Swiper>
        {/*<div className={styles.sliderNavPrev} />*/}
        {/*<div className={styles.sliderNavNext} />*/}
      </div>

    </section>
  );
}

export default Gallery;
