import styles from './Registration.module.scss';
import Container from '../container/Container';

function Registration() {
  return (
    <Container>
      <section className={styles.registration}>
        <h2 className={styles.title}><span className={styles.titleWrap}>Участвовать</span></h2>

        <ul className={styles.list}>
          <li className={styles.item}>
            <h3 className={styles.itemTitle}>Ограниченное количество мест</h3>
            <p className={styles.itemDesc}>Забронируй свое место на Гонке, иначе кто-то станет героем вместо тебя</p>
          </li>
          <li className={styles.item}>
            <h3 className={styles.itemTitle}>Прими вызов</h3>
            <p className={styles.itemDesc}>Выйди из зоны комфорта и узнай, на что ты способен. Соверши поступок, которым будешь гордиться.</p>
          </li>
        </ul>

        <a className={styles.btn} href="#citychoice">Принять участие</a>
      </section>
    </Container>
  );
}

export default Registration;
