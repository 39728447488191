import styles from './WhoFor.module.scss';
import { whoForList } from './whoForData';
import { useState } from 'react';

function WhoFor() {
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (evt) => {
    const index = +evt.target.dataset.index
    if (index !== activeTab) setActiveTab(index);
  };

  return (
    <section className={styles.whoFor}>
      <ul className={styles.list}>
        { whoForList.map((item) => (
          <li className={`${styles.item} ${activeTab === item.id ? styles.itemActive : ``}`} key={item.id}>
            <h2 className={styles.title}>{item.title}</h2>
            <p className={styles.desc}>{item.desc}</p>
            <img className={styles.img} src={item.background} alt="Фото участников"/>
            <div className={styles.clickWrap} onClick={handleChangeTab} data-index={item.id} />
          </li>
        )) }
      </ul>
    </section>
  );
}

export default WhoFor;
