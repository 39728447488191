import styles from './Map.module.scss';
import 'leaflet/dist/leaflet.css';
import mapPin from '../../images/pin.png';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as YandexLogo } from '../../images/svg/yandex-logo.svg';
import { ReactComponent as GoogleLogo } from '../../images/svg/google-logo.svg';
import { getLink } from 'geolinks';
import leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useSelector } from 'react-redux';
import { getSelectedCity } from '../../store/app/selectors';


function MapComponent() {
  const city = useSelector(getSelectedCity);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const MOVE_RIGHT_MAP = 0.008;
  const MOVE_BOTTOM_MAP = 0.0025;
  const MOBILE_MAX_WIDTH = 768;
  const [moveMapRight, setMoveMapRight] = useState(MOVE_RIGHT_MAP);
  const [moveMapBottom, setMoveMapBottom] = useState(0);
  const [googleMapLink, setGoogleMapLink] = useState("/");
  const [yandexMapLink, setYandexMapLink] = useState("/");
  const mapRef = useRef(null);
  const markers = leaflet.layerGroup();
  const [map, setMap] = useState(null);
  useEffect(() => {
    if (city && Object.keys(city).length > 0 && Object.keys(city.geo).length > 0) {
      setLat(city.geo.lat);
      setLon(city.geo.lon);

      setGoogleMapLink(getLink("google", { lat: city.geo.lat, lng: city.geo.lon, zoom: 16, language: `ru` }));
      setYandexMapLink(getLink("yandex", { lat: city.geo.lat, lng: city.geo.lon, zoom: 16 }));
    }
  }, [city]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < MOBILE_MAX_WIDTH) {
        setMoveMapRight(0);
        setMoveMapBottom(MOVE_BOTTOM_MAP);
      }
      if (window.innerWidth > MOBILE_MAX_WIDTH) {
        setMoveMapRight(MOVE_RIGHT_MAP);
        setMoveMapBottom(0);
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (mapRef.current !== null && map === null && lat !== 0 && lon !== 0) {
      const instance = leaflet.map(mapRef.current, {
        center: {
          lat: lat,
          lng: lon,
        },
        zoom: 8,
      });

      leaflet
        .tileLayer(
          'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
          {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          },
        )
        .addTo(instance);

      instance.setView(new leaflet.LatLng(lat + moveMapBottom, lon - moveMapRight), 16);

      setMap(instance);
    }

  }, [mapRef, map, city, lat, lon]);

  const defaultCustomIcon = leaflet.icon({
    iconUrl: mapPin,
    iconSize: [41, 41],
    iconAnchor: [13, 30],
  });

  useEffect(() => {
    if (map) {
      markers.clearLayers();

      const marker = leaflet
        .marker({
          lat: lat,
          lng: lon,
        }, {
          icon: defaultCustomIcon,
        });
      markers.addLayer(marker);
      markers.addTo(map);
    }

    return () => {
      markers.clearLayers();
    };
  }, [map, defaultCustomIcon, markers, city, lat, lon]);


  return (
    <> {city?.geo?.lat ?
      <section className={styles.map}>

        <div
          className={styles.mapContainer}
          id="map"
          style={{ height: '100%' }}
          ref={mapRef}
        />


        <div className={styles.content} id="whereis">
          <div className={styles.address}>
            <h2 className={styles.title}>Как добраться</h2>
            <p className={styles.text}>Построить маршрут:</p>
            <div className={styles.logoWrap}>
              <a href={yandexMapLink} target="_blank" rel="noopener noreferrer">
                <YandexLogo className={styles.logo} />
              </a>
              <a href={googleMapLink} target="_blank" rel="noopener noreferrer">
                <GoogleLogo className={styles.logo} />
              </a>
            </div>
            <p className={styles.text}>Приезжайте с болельщиками, для них вход бесплатный!</p>
          </div>
          <div className={styles.feedback}>
            <h3 className={styles.feedbackTitle}>Остались вопросы?</h3>
            <a href="/feedback" className={styles.btn}>Связаться с нами</a>
          </div>
        </div>

      </section> : null}
    </>
  );
}

export default MapComponent;
