import styles from './Levels.module.scss';
import Container from '../container/Container';
import membersImg from '../../images/members1@1x.png';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { formats } from './Levels-data';
import { useRect } from '../../hooks/useRect';
import { useEffect, useState } from 'react';

function Levels() {
  const [isActiveLevelsWrapY, setActiveLevelsWrapY] = useState(false);
  const [levelsWrapRect, levelsWrapRef] = useRect();

  useEffect(() => {
    const windowY = Math.floor(window.scrollY);

    if (windowY > (levelsWrapRect.top + windowY - 300)) setActiveLevelsWrapY(true);
  });

  return (
    <section className={`${styles.levels} `} ref={levelsWrapRef}>
      <Container>
        <div className={`${styles.wrapper} ${isActiveLevelsWrapY ? styles.levelsWrapAnimate : ''}`}>
          <div>
            <b className={styles.slogan}>Для каждого своя</b>
            <img className={styles.img} src={membersImg} alt="Участники"/>
          </div>
          <Tabs className={styles.tabs}>
            <TabList className={styles.tabList}>
              { formats.map((format) => (
                <Tab className={styles.tab} key={format.id}>
                  <format.icon className={styles.tabIcon} />
                  <span>{format.name}</span>
                </Tab>
              )) }
            </TabList>
            { formats.map((format) => (
              <TabPanel key={format.id}>
                <h3 className={styles.tabPanelTitle}>{format.title}</h3>
                <p className={styles.tabPanelText}>
                  {format.desc}
                </p>
                <ul className={styles.paramList}>
                  {format.params.map((param) => (
                    <li className={styles.paramItem} key={param.id}>
                      <param.icon className={styles.paramIcon} />
                      <div className={styles.paramWrapper}>
                        <h4 className={styles.paramLabel}>{param.label}</h4>
                        <span className={styles.bar} style={{width: `${param.value}%`}} />
                      </div>
                    </li>
                  ))}
                </ul>
                <div className={styles.recFormatWrapper}>
                  <p>Рекомендуемый формат:</p>
                  <p>{format.recFormat}</p>
                </div>
              </TabPanel>
            )) }

          </Tabs>
        </div>
      </Container>
    </section>
  );
}

export default Levels;
