import styles from './FormatBuyPopup.module.scss';
import Popup from '../popup/Popup';
import { useEffect, useState } from 'react';
import api, { HERO_API } from '../../../api';
// import GoodsListInPopup from '../../Goods/GoodsListInPopup';

const getTotalCost = (price, count = 1, insurance = 0, goods = 0) => {
  return (+((price * count) + (insurance * count) + goods).toFixed(2));
};

const FormatBuyPopup = ({handleStatePopup, format}) => {
  const [insurance, setInsurance] = useState(false); // on/off insurance
  const [checkTown, setCheckTown] = useState(false);
  const [eventGoods, setEventGoods] = useState([]);
  const [goodsSize, setGoodsSize] = useState(`XXS`);
  const [isAddGoods, setIsAddGoods] =useState(false);

  const handleChangeInput = () => setInsurance(!insurance);

  const handleRedirectChange = (evt) => {
    evt.preventDefault();

    const count = format.team_all ? format.max_count : 1;

    if (checkTown) {
      // redirect
      if (isAddGoods) {
        window.location.href = `${HERO_API}/basket-fill-outside/event?public_id=${format.event_public_id}&city_id=${format.city.id}&format_id=${format.public_id}&insurance=${insurance}&shop_id=${eventGoods[0].public_id}&shop_size=${goodsSize}&shop_count=1`;
      } else {
        window.location.href = `${HERO_API}/basket-fill-outside/event?public_id=${format.event_public_id}&city_id=${format.city.id}&format_id=${format.public_id}&insurance=${insurance}&count=${count}`;
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/shop/related/trail2021`);
        if (response.data) setEventGoods(response.data.values);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);

  return (
    <Popup handleStatePopup={handleStatePopup} outsideStyles={styles}>

      <div className={styles.descBlock}>
        <h2 className={styles.title}>Подтверждение об ознакомлении</h2>
        <div className={styles.desc}>
          <ul>
            {format.requirements.map(el => <li key={el}>{el}</li>)}
          </ul>
        </div>
        <div className={styles.formWrapper}>
          <div className={styles.cityWrap}>
            <p className={styles.cityText}>Город:&nbsp;&nbsp;<span className={styles.city}>{format.address}</span></p>
            <div className={styles.inputWrap}>
              <input
                defaultValue={checkTown}
                onChange={() => setCheckTown(!checkTown)}
                className={`${styles.checkbox} visually-hidden`}
                type="checkbox" id="check-town-ref"
              />
              <label className={styles.label} htmlFor="check-town-ref">С медицинской справкой ознакомлен и город подтверждаю</label>
              {
                format.insurance_available ?
                  <>
                    <input
                      value={insurance}
                      onChange={handleChangeInput}
                      className={`${styles.checkbox} visually-hidden`}
                      type="checkbox" id="check-insurance"
                    />
                    <label className={styles.label} htmlFor="check-insurance">Страховка строго 18+</label>
                  </>
                  : null
              }
            </div>
          </div>
          <div className={styles.btnWrap}>
            {/* <button data-state="close" onClick={handleStatePopup} type="button"
              className={styles.btn}
            >Остаться на сайте</button> */}
            <button
              className={styles.btn}
              type="button" onClick={handleRedirectChange}
              disabled={!checkTown}
            >Перейти к покупке</button>
          </div>
        </div>
      </div>

      <div className={styles.costBlock}>
        <b className={styles.worth}>Стоимость:</b>
        {format.insurance_available && insurance ?
          <p className={styles.counts}>
            <span>Страховка</span>
            <span className={styles.cost}>{insurance ? (format.insurance_cost * 1) : 0} &#8381;</span>
          </p>
          : null
        }
        <p className={styles.counts}>
          <span>Билет</span>
          <span className={styles.cost}>{format.price} &#8381;</span>
        </p>
        { format.team_all &&
          <p className={styles.counts}>
            <span>Количество билетов</span>
            <span className={styles.cost}>{format.max_count}</span>
          </p>
        }
        { isAddGoods &&
        <p className={styles.counts}>
          <span>{eventGoods[0].title}</span>
          <span className={styles.cost}>{eventGoods[0].price} &#8381;</span>
        </p>
        }
        <p className={styles.counts}>
          <span>Итого:</span>
          <span className={styles.cost}>{getTotalCost(format.price, format.team_all ? format.max_count : 1, insurance && format.insurance_cost, isAddGoods && eventGoods[0].price)} &#8381;</span>
        </p>
      </div>

      {/*<div className={styles.goods}>*/}
      {/*  {eventGoods.length && <GoodsListInPopup eventGoods={eventGoods} goodsSize={setGoodsSize} handleAddGoods={setIsAddGoods} />}*/}
      {/*</div>*/}
    </Popup>
  )
}

export default FormatBuyPopup;
