import styles from './Faq.module.scss';
import Container from '../container/Container';
import { faqList } from './faq-data';
import { useState } from 'react';

function Faq() {
  const [activeTab, setActiveTab] = useState(0);
  const [accordionActiveTab, setAccordionActiveTab] = useState(null);

  const handleClickTab = (evt) => {
    const index = +evt.target.dataset.index;
    if (index !== activeTab) {
      setActiveTab(index);
      setAccordionActiveTab(null);
    }
  }

  const handleClickAccordionTab = (evt) => {
    const index = +evt.target.dataset.index;
    if (index !== accordionActiveTab) setAccordionActiveTab(index);
    else if (index === accordionActiveTab) setAccordionActiveTab(null);
  }

  return (
    <Container>
      <section className={styles.faq}>
        <ul className={styles.tabs}>
          { faqList.map((item) => (
            <li
              className={`${styles.tab} ${activeTab === item.id ? styles.tabActive : ``}`}
              key={item.id}
              data-index={item.id}
              onClick={handleClickTab}
            >
              {item.tabName}
            </li>
          )) }
        </ul>

        { faqList.map((item) => (
          <div className={`${styles.tabPanel} ${activeTab === item.id ? styles.tabPanelActive : ``}`} key={item.id}>
            { item.questions.map((el) => (
              <div key={el.id}>
                <h3 className={styles.accordionTab} onClick={handleClickAccordionTab} data-index={el.id}>{el.question}</h3>
                <div className={`${styles.accordionPanel} ${accordionActiveTab === el.id ? styles.accordionPanelActive : ``}`}>{el.answer}</div>
              </div>
            )) }
          </div>
        )) }

      </section>

    </Container>
  );
}

export default Faq;
