import gallery1 from '../../images/gallery/gallery1.jpg';
import gallery2 from '../../images/gallery/gallery2.jpg';
import gallery3 from '../../images/gallery/gallery3.jpg';
import gallery4 from '../../images/gallery/gallery4.jpg';
import gallery5 from '../../images/gallery/gallery5.jpg';
import gallery6 from '../../images/gallery/gallery6.jpg';
import gallery7 from '../../images/gallery/gallery7.jpg';
import gallery8 from '../../images/gallery/gallery8.jpg';
import gallery9 from '../../images/gallery/gallery9.jpg';
import gallery10 from '../../images/gallery/gallery10.jpg';
import gallery11 from '../../images/gallery/gallery11.jpg';
import gallery12 from '../../images/gallery/gallery12.jpg';
import gallery13 from '../../images/gallery/gallery13.jpg';
import gallery14 from '../../images/gallery/gallery14.jpg';
import gallery15 from '../../images/gallery/gallery15.jpg';
import gallery16 from '../../images/gallery/gallery16.jpg';
import gallery17 from '../../images/gallery/gallery17.jpg';
import gallery18 from '../../images/gallery/gallery18.jpg';
import gallery19 from '../../images/gallery/gallery19.jpg';

const galleryPhotos = [
  {
    id: 1,
    img: gallery1,
  },
  {
    id: 2,
    img: gallery2,
  },
  {
    id: 3,
    img: gallery3,
  },
  {
    id: 4,
    img: gallery4,
  },
  {
    id: 5,
    img: gallery5,
  },
  {
    id: 6,
    img: gallery6,
  },
  {
    id: 7,
    img: gallery7,
  },
  {
    id: 8,
    img: gallery8,
  },
  {
    id: 9,
    img: gallery9,
  },
  {
    id: 10,
    img: gallery10,
  },
  {
    id: 11,
    img: gallery11,
  },
  {
    id: 12,
    img: gallery12,
  },
  {
    id: 13,
    img: gallery13,
  },
  {
    id: 14,
    img: gallery14,
  },
  {
    id: 15,
    img: gallery15,
  },
  {
    id: 16,
    img: gallery16,
  },
  {
    id: 17,
    img: gallery17,
  },
  {
    id: 18,
    img: gallery18,
  },
  {
    id: 19,
    img: gallery19,
  },
];

export { galleryPhotos };
