import styles from './TopPage.module.scss';
import { ReactComponent as LogoLiga } from '../../images/svg/main-logo.svg';
import raceLigaPng1x from '../../images/liga-race@1x.png';
import raceLigaPng2x from '../../images/liga-race@2x.png';
import raceLigaWebp1x from '../../images/webp/liga-race@1x.webp';
import raceLigaWebp2x from '../../images/webp/liga-race@2x.webp';
import raceLigaAvif1x from '../../images/avif/liga-race@2x.avif';
import raceLigaAvif2x from '../../images/avif/liga-race@2x.avif';

import cloud2 from '../../images/cloud2@1x.png';
import cloud3 from '../../images/cloud3@1x.png';
import leftHand from '../../images/left-hand@1x.png';
import rightHand from '../../images/right-hand@1x.png';
import legs from '../../images/legs@1x.png';
import land from '../../images/land@1x.png';
import { useEffect, useState } from 'react';

function TopPage() {
  const [isAnimTopPage, setAnimTopPage] = useState(true);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 991) setAnimTopPage(false);
      if (window.innerWidth > 991) setAnimTopPage(true);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className={`${styles.topPage} ${isAnimTopPage ? styles.topPageAnimate : ``}`}>
      <div className={styles.landWrap}>
        <img className={styles.land} src={land} alt="Земля"/>
      </div>
      <img className={`${styles.cloud} ${styles.cloud2}`} src={cloud2} alt="Облако"/>
      <img className={`${styles.cloud} ${styles.cloud3}`} src={cloud3} alt="Облако"/>
      <img className={`${styles.hand} ${styles.leftHand}`} src={leftHand} alt="Рука"/>
      <img className={`${styles.hand} ${styles.rightHand}`} src={rightHand} alt="Рука"/>
      <img className={styles.legs} src={legs} alt="Ноги"/>
      <LogoLiga className={styles.logoLiga} />
      <picture>
        <source type="image/avif" srcSet={`${raceLigaAvif1x} 1x, ${raceLigaAvif2x} 2x`}/>
        <source type="image/webp" srcSet={`${raceLigaWebp1x} 1x, ${raceLigaWebp2x} 2x`}/>
        <img
          className={styles.logoRace}
          width="422"
          height="214"
          src={raceLigaPng1x}
          srcSet={`${raceLigaPng2x} 2x`}
          alt="Логотип Гонки Героев"/>
      </picture>
      <div className={styles.cloud1} />
      { !isAnimTopPage &&  <a className={styles.anchor} href="#citychoice">Принять участие</a>}
    </section>
  );
}

export default TopPage;
