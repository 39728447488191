export const routes = {
  main: {
    path:`/`,
    name: `Главная`,
  },
  event: {
    path: `/event`,
    name: `Мероприятие`,
  },
  personal: {
    path: `/me`,
    name: `Личный кабинет`,
  },
  calendar: {
    path:`/calendar`,
    name: `Календарь мероприятий`,
  },
  basket: {
    path:`/basket`,
    name: `Корзина`,
  },
  virtualEvents: {
    path: `/virtualEvents`,
    name: `Виртуальные события`,
  },
  about: {
    path:`/about`,
    name: `О нас`,
  },
  partners: {
    path:`/partners`,
    name: `Партнеры`,
  },
  corporate: {
    path:`/corp`,
    name: `Корпоративным клиентам`,
  },
  feedback: {
    path:`/feedback`,
    name: `Обратная связь`,
  },
  myEvents: {
    path:`/myevents`,
    name: `Мои мероприятия`,
    child: {
      name: `Регистрация билета`,
    },
  },
  myOrders: {
    path:`/myorders`,
    name: `Мои покупки`,
    child: {
      name: `Детали заказа`,
    },
  },
  news: {
    path: `/news`,
    name: `Новости Лиги Героев`,
  },
  nizhny: {
    path: `/nizhny`,
    name: `Марафон 800 Нижний Новгород`,
  },
  shop: {
    path: `/new-shop`,
    name: `Интернет-магазин`,
  },
  product: {
    path: `/new-shop/product`,
    name: `Страница товара`,
  },
  category: {
    path: `/new-shop/category`,
    name: `Категория товаров в интернет-магазине`,
  }
};