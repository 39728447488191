import { setEventsList } from './action';

const fetchEventsList = () => (dispatch, _getState, api) => {
  api.get(`api/event_city/type/gonka`).then((r) => {
    if (r.status === 200) {
      dispatch(setEventsList(r.data.values))
    }
  })
};

export {
  fetchEventsList,
};
