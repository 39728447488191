import styles from './Emodions.module.scss';
import Container from '../container/Container';
import { createRef, useEffect, useState } from 'react';

function Emotions() {
  const [scrollY, setScrollY] = useState(false);
  const [emotionsY, setEmotionsY] = useState(null);
  const emotionsRef = createRef();

  useEffect(() => {
    setEmotionsY(emotionsRef.current.getBoundingClientRect().top);

    window.addEventListener('scroll', () => {
      (Math.floor(window.scrollY) + 800) > emotionsY && emotionsY !== null && setScrollY(true);
    });

  }, [emotionsY, emotionsRef]);

  return (
    <Container>
      <section className={`${styles.emotions} ${scrollY ? styles.emotionsAnimate : ''}`} ref={emotionsRef}>
        <div className={styles.content}>
          <h2 className={styles.title}>Настоящие эмоции</h2>
          <div>
            <p className={styles.text}>
              Гонка Героев — крутой забег с препятствиями в твоем городе: драйв, поддержка и уверенность для каждого!
              Участники мероприятия преодолевают полосу препятствий, разработанную профессиональными инженерами
              и опытными инструкторами.
            </p>
            <p className={styles.text}>
              Рукоходы и переправы, рвы и поля с колючей проволокой, достойная награда и чувство гордости на финише — и это
              только часть твоего незабываемого приключения.
            </p>
            <p className={styles.text}>
              Участие доступно для желающих любого уровня подготовки. Но не пытайтесь выйти сухим из воды: вы будете
              по колено в грязи и по уши в восторге.
            </p>
          </div>
        </div>
      </section>

      <a className={styles.btn} href="#citychoice">Принять участие</a>
    </Container>
  );
}

export default Emotions;
